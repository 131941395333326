// Color palette focused on accessibility

$text-color: $oc-gray-9;
$secondary-text-color: #6a7075;

$link-color: $oc-indigo-9;
$link-visited-color: $oc-violet-9;
$link-hover-color: $oc-blue-9;

$border-color: $oc-gray-6;
$separator-color: $oc-gray-3;
