// @format

@import "libs/sanitize/sanitize";
@import "libs/sanitize/forms";
@import "libs/sanitize/typography";
@import "libs/open-color";

@import "variables/colors";

@import "mixins/breakpoint";

@import "components/header";
@import "components/footer";
@import "components/home";
@import "components/article";
@import "components/recipe";
@import "components/profile_picture";

body {
  color: $text-color;
  font-size: 16px;
  max-width: 750px;
  margin: 0 auto;
  padding: 0 1em;

  @include breakpoint(sm) {
    font-size: 18px;
  }
}

a {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }

  &:visited {
    color: $link-visited-color;
  }
}

ol,
ul {
  padding-left: 2.5em;

  li {
    padding: 0.3em 0 0.3em 0.25em;
  }
}
